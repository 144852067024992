var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('breadcumb',{attrs:{"page":'Filter',"folder":'Datatables'}}),_c('b-card',{staticClass:"wrapper"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"search-options":{
        enabled: true,
        placeholder: 'Search this table',
      },"pagination-options":{
        enabled: true,
        mode: 'records',
      },"styleClass":"tableOne vgt-table","rows":_vm.rows},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'img')?_c('span',[_c('img',{staticClass:"rounded",staticStyle:{"width":"50px","height":"50px"},attrs:{"src":props.row.img,"alt":"corrupted img"}})]):_vm._e()]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }